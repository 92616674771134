import { default as _91id_939hgeP2mczNMeta } from "/var/www/backend/pages/admin-groups/[id].vue?macro=true";
import { default as indexYud3KhUK8lMeta } from "/var/www/backend/pages/admin-groups/index.vue?macro=true";
import { default as admin_45passwordsPIjfNQRahMeta } from "/var/www/backend/pages/admin-password.vue?macro=true";
import { default as _91id_9310YrSyyEtvMeta } from "/var/www/backend/pages/admins/[id].vue?macro=true";
import { default as indexq8UvPamK29Meta } from "/var/www/backend/pages/admins/index.vue?macro=true";
import { default as _91id_93MEqhggplGpMeta } from "/var/www/backend/pages/article-groups/[id].vue?macro=true";
import { default as indexcsuzfoVlFDMeta } from "/var/www/backend/pages/article-groups/index.vue?macro=true";
import { default as _91id_93e6CCkaiwO6Meta } from "/var/www/backend/pages/articles/[id].vue?macro=true";
import { default as indexFOMRnIMexNMeta } from "/var/www/backend/pages/articles/index.vue?macro=true";
import { default as _91id_93KDEi65yp3KMeta } from "/var/www/backend/pages/clients/[id].vue?macro=true";
import { default as indexm7kh3wMn2JMeta } from "/var/www/backend/pages/clients/index.vue?macro=true";
import { default as code_45checkfFNAV9ccpzMeta } from "/var/www/backend/pages/code-check.vue?macro=true";
import { default as _91id_93ZYuc4CdT2KMeta } from "/var/www/backend/pages/email-template/[id].vue?macro=true";
import { default as indexm7ILpX55CZMeta } from "/var/www/backend/pages/email-template/index.vue?macro=true";
import { default as indexU5dc1Lycd8Meta } from "/var/www/backend/pages/event-dates-orders/index.vue?macro=true";
import { default as _91id_937nTtzfg54pMeta } from "/var/www/backend/pages/event-dates/[id].vue?macro=true";
import { default as editVceyoLDv50Meta } from "/var/www/backend/pages/event-dates/edit.vue?macro=true";
import { default as indexeLtW9cUowxMeta } from "/var/www/backend/pages/event-dates/index.vue?macro=true";
import { default as _91id_93vJp40CCY57Meta } from "/var/www/backend/pages/events/[id].vue?macro=true";
import { default as index6bZ1NGs7MtMeta } from "/var/www/backend/pages/events/index.vue?macro=true";
import { default as export_45datev1r8Pj1DBORMeta } from "/var/www/backend/pages/export/export-datev.vue?macro=true";
import { default as _91id_935Jngsq8o18Meta } from "/var/www/backend/pages/filters/[id].vue?macro=true";
import { default as indexEJVuhZzZKjMeta } from "/var/www/backend/pages/filters/index.vue?macro=true";
import { default as import_45usersRboh79OZiFMeta } from "/var/www/backend/pages/import/import-users.vue?macro=true";
import { default as indexo4Y50yezZ3Meta } from "/var/www/backend/pages/index.vue?macro=true";
import { default as loginwQQpoBFNGGMeta } from "/var/www/backend/pages/login.vue?macro=true";
import { default as indexeXT2hGmbZmMeta } from "/var/www/backend/pages/membership-types/index.vue?macro=true";
import { default as _91id_93oS5koSvCXjMeta } from "/var/www/backend/pages/newsletters/[id].vue?macro=true";
import { default as index9DF2eruWr8Meta } from "/var/www/backend/pages/newsletters/index.vue?macro=true";
import { default as indexVgMNdImwH8Meta } from "/var/www/backend/pages/options/index.vue?macro=true";
import { default as _91id_93KZtInUrIWOMeta } from "/var/www/backend/pages/orders/[id].vue?macro=true";
import { default as indexAB4Rig1L1fMeta } from "/var/www/backend/pages/orders/index.vue?macro=true";
import { default as password_45resetHKyVPaNE6hMeta } from "/var/www/backend/pages/password-reset.vue?macro=true";
import { default as _91id_93RfgUUsb2VIMeta } from "/var/www/backend/pages/payments/[id].vue?macro=true";
import { default as indexHPkfGKyuGKMeta } from "/var/www/backend/pages/payments/index.vue?macro=true";
import { default as _91id_93vnvneehNfqMeta } from "/var/www/backend/pages/plan-variants/[id].vue?macro=true";
import { default as _91id_93UmJYnEGCmlMeta } from "/var/www/backend/pages/plans/[id].vue?macro=true";
import { default as indexer8nc91BA6Meta } from "/var/www/backend/pages/plans/index.vue?macro=true";
import { default as index5jRlOdGKKTMeta } from "/var/www/backend/pages/prices/index.vue?macro=true";
import { default as _91id_93NVQEwmDNHxMeta } from "/var/www/backend/pages/product-groups/[id].vue?macro=true";
import { default as indexYLAqWUfwrQMeta } from "/var/www/backend/pages/product-groups/index.vue?macro=true";
import { default as _91id_933c9PmYosEkMeta } from "/var/www/backend/pages/products/[id].vue?macro=true";
import { default as indexB1GftZxxuCMeta } from "/var/www/backend/pages/products/index.vue?macro=true";
import { default as _91id_93ingMPapy43Meta } from "/var/www/backend/pages/promotion-groups/[id].vue?macro=true";
import { default as indexigdQiCoPKWMeta } from "/var/www/backend/pages/promotion-groups/index.vue?macro=true";
import { default as _91id_93XtMx90SxzDMeta } from "/var/www/backend/pages/promotions/[id].vue?macro=true";
import { default as indexdsCRZ4OuRoMeta } from "/var/www/backend/pages/promotions/index.vue?macro=true";
import { default as indexfNCWBsy8ksMeta } from "/var/www/backend/pages/qs-settings/index.vue?macro=true";
import { default as _91id_93baBUBXWEAKMeta } from "/var/www/backend/pages/roles/[id].vue?macro=true";
import { default as indexcxpUC8tPnxMeta } from "/var/www/backend/pages/roles/index.vue?macro=true";
import { default as _91id_93PlmuqIV0u7Meta } from "/var/www/backend/pages/rooms/[id].vue?macro=true";
import { default as indexYR5xgr1UeEMeta } from "/var/www/backend/pages/rooms/index.vue?macro=true";
import { default as backupjkAHYAs6AYMeta } from "/var/www/backend/pages/sales-dashboard/backup.vue?macro=true";
import { default as indexhXfQGGOlIPMeta } from "/var/www/backend/pages/sales-dashboard/index.vue?macro=true";
import { default as _91id_930VsCenY7IAMeta } from "/var/www/backend/pages/sales-groups/[id].vue?macro=true";
import { default as indexHnUpGfPLJjMeta } from "/var/www/backend/pages/sales-groups/index.vue?macro=true";
import { default as _91id_93gDX5NfYZfVMeta } from "/var/www/backend/pages/shipping-methods/[id].vue?macro=true";
import { default as indexQFEOc5W8ZnMeta } from "/var/www/backend/pages/shipping-methods/index.vue?macro=true";
import { default as _91id_93w36l84wOmXMeta } from "/var/www/backend/pages/shops/[id].vue?macro=true";
import { default as indexek49S2yUJfMeta } from "/var/www/backend/pages/shops/index.vue?macro=true";
import { default as indexSOmuuEvVQxMeta } from "/var/www/backend/pages/skategorie/index.vue?macro=true";
import { default as _91id_932ZMcrZFAjtMeta } from "/var/www/backend/pages/support/[id].vue?macro=true";
import { default as indexk2LClDTqt2Meta } from "/var/www/backend/pages/support/index.vue?macro=true";
import { default as _91id_93LnBkr6WJ3TMeta } from "/var/www/backend/pages/surveys/[id].vue?macro=true";
import { default as indexnk6GmQwrmPMeta } from "/var/www/backend/pages/surveys/index.vue?macro=true";
import { default as _91id_93BO4W993J9sMeta } from "/var/www/backend/pages/tasks/[id].vue?macro=true";
import { default as indexNEJB3xfLJEMeta } from "/var/www/backend/pages/tasks/index.vue?macro=true";
import { default as _91id_93RGem5az2L7Meta } from "/var/www/backend/pages/taxes/[id].vue?macro=true";
import { default as indexg77ds3wn6dMeta } from "/var/www/backend/pages/taxes/index.vue?macro=true";
import { default as _91id_9330saqBDnyoMeta } from "/var/www/backend/pages/user-groups/[id].vue?macro=true";
import { default as indexcIUrtaNoJaMeta } from "/var/www/backend/pages/user-groups/index.vue?macro=true";
import { default as _91id_936JonhxMS9iMeta } from "/var/www/backend/pages/users/[id].vue?macro=true";
import { default as indexG39D6R4MfKMeta } from "/var/www/backend/pages/users/index.vue?macro=true";
import { default as index4zwd3HuSI1Meta } from "/var/www/backend/pages/utilization-dashboard/index.vue?macro=true";
import { default as _91id_93AbwnSb7zWNMeta } from "/var/www/backend/pages/vouchers/[id].vue?macro=true";
import { default as indexfZniSSOnipMeta } from "/var/www/backend/pages/vouchers/index.vue?macro=true";
export default [
  {
    name: "admin-groups-id",
    path: "/admin-groups/:id()",
    meta: _91id_939hgeP2mczNMeta || {},
    component: () => import("/var/www/backend/pages/admin-groups/[id].vue")
  },
  {
    name: "admin-groups",
    path: "/admin-groups",
    meta: indexYud3KhUK8lMeta || {},
    component: () => import("/var/www/backend/pages/admin-groups/index.vue")
  },
  {
    name: "admin-password",
    path: "/admin-password",
    meta: admin_45passwordsPIjfNQRahMeta || {},
    component: () => import("/var/www/backend/pages/admin-password.vue")
  },
  {
    name: "admins-id",
    path: "/admins/:id()",
    meta: _91id_9310YrSyyEtvMeta || {},
    component: () => import("/var/www/backend/pages/admins/[id].vue")
  },
  {
    name: "admins",
    path: "/admins",
    meta: indexq8UvPamK29Meta || {},
    component: () => import("/var/www/backend/pages/admins/index.vue")
  },
  {
    name: "article-groups-id",
    path: "/article-groups/:id()",
    meta: _91id_93MEqhggplGpMeta || {},
    component: () => import("/var/www/backend/pages/article-groups/[id].vue")
  },
  {
    name: "article-groups",
    path: "/article-groups",
    meta: indexcsuzfoVlFDMeta || {},
    component: () => import("/var/www/backend/pages/article-groups/index.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: _91id_93e6CCkaiwO6Meta || {},
    component: () => import("/var/www/backend/pages/articles/[id].vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexFOMRnIMexNMeta || {},
    component: () => import("/var/www/backend/pages/articles/index.vue")
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: _91id_93KDEi65yp3KMeta || {},
    component: () => import("/var/www/backend/pages/clients/[id].vue")
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexm7kh3wMn2JMeta || {},
    component: () => import("/var/www/backend/pages/clients/index.vue")
  },
  {
    name: "code-check",
    path: "/code-check",
    meta: code_45checkfFNAV9ccpzMeta || {},
    component: () => import("/var/www/backend/pages/code-check.vue")
  },
  {
    name: "email-template-id",
    path: "/email-template/:id()",
    meta: _91id_93ZYuc4CdT2KMeta || {},
    component: () => import("/var/www/backend/pages/email-template/[id].vue")
  },
  {
    name: "email-template",
    path: "/email-template",
    meta: indexm7ILpX55CZMeta || {},
    component: () => import("/var/www/backend/pages/email-template/index.vue")
  },
  {
    name: "event-dates-orders",
    path: "/event-dates-orders",
    meta: indexU5dc1Lycd8Meta || {},
    component: () => import("/var/www/backend/pages/event-dates-orders/index.vue")
  },
  {
    name: "event-dates-id",
    path: "/event-dates/:id()",
    meta: _91id_937nTtzfg54pMeta || {},
    component: () => import("/var/www/backend/pages/event-dates/[id].vue")
  },
  {
    name: "event-dates-edit",
    path: "/event-dates/edit",
    meta: editVceyoLDv50Meta || {},
    component: () => import("/var/www/backend/pages/event-dates/edit.vue")
  },
  {
    name: "event-dates",
    path: "/event-dates",
    meta: indexeLtW9cUowxMeta || {},
    component: () => import("/var/www/backend/pages/event-dates/index.vue")
  },
  {
    name: "events-id",
    path: "/events/:id()",
    meta: _91id_93vJp40CCY57Meta || {},
    component: () => import("/var/www/backend/pages/events/[id].vue")
  },
  {
    name: "events",
    path: "/events",
    meta: index6bZ1NGs7MtMeta || {},
    component: () => import("/var/www/backend/pages/events/index.vue")
  },
  {
    name: "export-export-datev",
    path: "/export/export-datev",
    meta: export_45datev1r8Pj1DBORMeta || {},
    component: () => import("/var/www/backend/pages/export/export-datev.vue")
  },
  {
    name: "filters-id",
    path: "/filters/:id()",
    meta: _91id_935Jngsq8o18Meta || {},
    component: () => import("/var/www/backend/pages/filters/[id].vue")
  },
  {
    name: "filters",
    path: "/filters",
    meta: indexEJVuhZzZKjMeta || {},
    component: () => import("/var/www/backend/pages/filters/index.vue")
  },
  {
    name: "import-import-users",
    path: "/import/import-users",
    meta: import_45usersRboh79OZiFMeta || {},
    component: () => import("/var/www/backend/pages/import/import-users.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexo4Y50yezZ3Meta || {},
    component: () => import("/var/www/backend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginwQQpoBFNGGMeta || {},
    component: () => import("/var/www/backend/pages/login.vue")
  },
  {
    name: "membership-types",
    path: "/membership-types",
    component: () => import("/var/www/backend/pages/membership-types/index.vue")
  },
  {
    name: "newsletters-id",
    path: "/newsletters/:id()",
    meta: _91id_93oS5koSvCXjMeta || {},
    component: () => import("/var/www/backend/pages/newsletters/[id].vue")
  },
  {
    name: "newsletters",
    path: "/newsletters",
    meta: index9DF2eruWr8Meta || {},
    component: () => import("/var/www/backend/pages/newsletters/index.vue")
  },
  {
    name: "options",
    path: "/options",
    meta: indexVgMNdImwH8Meta || {},
    component: () => import("/var/www/backend/pages/options/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93KZtInUrIWOMeta || {},
    component: () => import("/var/www/backend/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexAB4Rig1L1fMeta || {},
    component: () => import("/var/www/backend/pages/orders/index.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetHKyVPaNE6hMeta || {},
    component: () => import("/var/www/backend/pages/password-reset.vue")
  },
  {
    name: "payments-id",
    path: "/payments/:id()",
    meta: _91id_93RfgUUsb2VIMeta || {},
    component: () => import("/var/www/backend/pages/payments/[id].vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexHPkfGKyuGKMeta || {},
    component: () => import("/var/www/backend/pages/payments/index.vue")
  },
  {
    name: "plan-variants-id",
    path: "/plan-variants/:id()",
    meta: _91id_93vnvneehNfqMeta || {},
    component: () => import("/var/www/backend/pages/plan-variants/[id].vue")
  },
  {
    name: "plans-id",
    path: "/plans/:id()",
    meta: _91id_93UmJYnEGCmlMeta || {},
    component: () => import("/var/www/backend/pages/plans/[id].vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexer8nc91BA6Meta || {},
    component: () => import("/var/www/backend/pages/plans/index.vue")
  },
  {
    name: "prices",
    path: "/prices",
    meta: index5jRlOdGKKTMeta || {},
    component: () => import("/var/www/backend/pages/prices/index.vue")
  },
  {
    name: "product-groups-id",
    path: "/product-groups/:id()",
    meta: _91id_93NVQEwmDNHxMeta || {},
    component: () => import("/var/www/backend/pages/product-groups/[id].vue")
  },
  {
    name: "product-groups",
    path: "/product-groups",
    meta: indexYLAqWUfwrQMeta || {},
    component: () => import("/var/www/backend/pages/product-groups/index.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_933c9PmYosEkMeta || {},
    component: () => import("/var/www/backend/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexB1GftZxxuCMeta || {},
    component: () => import("/var/www/backend/pages/products/index.vue")
  },
  {
    name: "promotion-groups-id",
    path: "/promotion-groups/:id()",
    meta: _91id_93ingMPapy43Meta || {},
    component: () => import("/var/www/backend/pages/promotion-groups/[id].vue")
  },
  {
    name: "promotion-groups",
    path: "/promotion-groups",
    meta: indexigdQiCoPKWMeta || {},
    component: () => import("/var/www/backend/pages/promotion-groups/index.vue")
  },
  {
    name: "promotions-id",
    path: "/promotions/:id()",
    meta: _91id_93XtMx90SxzDMeta || {},
    component: () => import("/var/www/backend/pages/promotions/[id].vue")
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: indexdsCRZ4OuRoMeta || {},
    component: () => import("/var/www/backend/pages/promotions/index.vue")
  },
  {
    name: "qs-settings",
    path: "/qs-settings",
    meta: indexfNCWBsy8ksMeta || {},
    component: () => import("/var/www/backend/pages/qs-settings/index.vue")
  },
  {
    name: "roles-id",
    path: "/roles/:id()",
    meta: _91id_93baBUBXWEAKMeta || {},
    component: () => import("/var/www/backend/pages/roles/[id].vue")
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexcxpUC8tPnxMeta || {},
    component: () => import("/var/www/backend/pages/roles/index.vue")
  },
  {
    name: "rooms-id",
    path: "/rooms/:id()",
    meta: _91id_93PlmuqIV0u7Meta || {},
    component: () => import("/var/www/backend/pages/rooms/[id].vue")
  },
  {
    name: "rooms",
    path: "/rooms",
    meta: indexYR5xgr1UeEMeta || {},
    component: () => import("/var/www/backend/pages/rooms/index.vue")
  },
  {
    name: "sales-dashboard-backup",
    path: "/sales-dashboard/backup",
    meta: backupjkAHYAs6AYMeta || {},
    component: () => import("/var/www/backend/pages/sales-dashboard/backup.vue")
  },
  {
    name: "sales-dashboard",
    path: "/sales-dashboard",
    meta: indexhXfQGGOlIPMeta || {},
    component: () => import("/var/www/backend/pages/sales-dashboard/index.vue")
  },
  {
    name: "sales-groups-id",
    path: "/sales-groups/:id()",
    meta: _91id_930VsCenY7IAMeta || {},
    component: () => import("/var/www/backend/pages/sales-groups/[id].vue")
  },
  {
    name: "sales-groups",
    path: "/sales-groups",
    meta: indexHnUpGfPLJjMeta || {},
    component: () => import("/var/www/backend/pages/sales-groups/index.vue")
  },
  {
    name: "shipping-methods-id",
    path: "/shipping-methods/:id()",
    meta: _91id_93gDX5NfYZfVMeta || {},
    component: () => import("/var/www/backend/pages/shipping-methods/[id].vue")
  },
  {
    name: "shipping-methods",
    path: "/shipping-methods",
    meta: indexQFEOc5W8ZnMeta || {},
    component: () => import("/var/www/backend/pages/shipping-methods/index.vue")
  },
  {
    name: "shops-id",
    path: "/shops/:id()",
    meta: _91id_93w36l84wOmXMeta || {},
    component: () => import("/var/www/backend/pages/shops/[id].vue")
  },
  {
    name: "shops",
    path: "/shops",
    meta: indexek49S2yUJfMeta || {},
    component: () => import("/var/www/backend/pages/shops/index.vue")
  },
  {
    name: "skategorie",
    path: "/skategorie",
    meta: indexSOmuuEvVQxMeta || {},
    component: () => import("/var/www/backend/pages/skategorie/index.vue")
  },
  {
    name: "support-id",
    path: "/support/:id()",
    meta: _91id_932ZMcrZFAjtMeta || {},
    component: () => import("/var/www/backend/pages/support/[id].vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexk2LClDTqt2Meta || {},
    component: () => import("/var/www/backend/pages/support/index.vue")
  },
  {
    name: "surveys-id",
    path: "/surveys/:id()",
    meta: _91id_93LnBkr6WJ3TMeta || {},
    component: () => import("/var/www/backend/pages/surveys/[id].vue")
  },
  {
    name: "surveys",
    path: "/surveys",
    meta: indexnk6GmQwrmPMeta || {},
    component: () => import("/var/www/backend/pages/surveys/index.vue")
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    meta: _91id_93BO4W993J9sMeta || {},
    component: () => import("/var/www/backend/pages/tasks/[id].vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexNEJB3xfLJEMeta || {},
    component: () => import("/var/www/backend/pages/tasks/index.vue")
  },
  {
    name: "taxes-id",
    path: "/taxes/:id()",
    meta: _91id_93RGem5az2L7Meta || {},
    component: () => import("/var/www/backend/pages/taxes/[id].vue")
  },
  {
    name: "taxes",
    path: "/taxes",
    meta: indexg77ds3wn6dMeta || {},
    component: () => import("/var/www/backend/pages/taxes/index.vue")
  },
  {
    name: "user-groups-id",
    path: "/user-groups/:id()",
    meta: _91id_9330saqBDnyoMeta || {},
    component: () => import("/var/www/backend/pages/user-groups/[id].vue")
  },
  {
    name: "user-groups",
    path: "/user-groups",
    meta: indexcIUrtaNoJaMeta || {},
    component: () => import("/var/www/backend/pages/user-groups/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_936JonhxMS9iMeta || {},
    component: () => import("/var/www/backend/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexG39D6R4MfKMeta || {},
    component: () => import("/var/www/backend/pages/users/index.vue")
  },
  {
    name: "utilization-dashboard",
    path: "/utilization-dashboard",
    meta: index4zwd3HuSI1Meta || {},
    component: () => import("/var/www/backend/pages/utilization-dashboard/index.vue")
  },
  {
    name: "vouchers-id",
    path: "/vouchers/:id()",
    meta: _91id_93AbwnSb7zWNMeta || {},
    component: () => import("/var/www/backend/pages/vouchers/[id].vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    meta: indexfZniSSOnipMeta || {},
    component: () => import("/var/www/backend/pages/vouchers/index.vue")
  }
]