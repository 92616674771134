export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/clients`,

  async getClients() {
    return await $fetch(this.apiURL, { headers: { Authorization: useToken().value } });
  },

  async getClient(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },

  async saveQSToken(clientId, code) {
    const body = { clientId, code };

    return await $fetch(`${this.apiURL}/qs-oauth-flow`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },

  async revokeQsToken(clientId) {
    const body = { clientId };
    return await $fetch(`${this.apiURL}/revoke-qs-token`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body,
    });
  },
  async saveClient(client) {
    if (client.email_password === "gespeichert") {
      delete client.email_password;
    }
    if (client.openai_key === "gespeichert") {
      delete client.openai_key;
    }
    return await $fetch(this.apiURL, {
      method: client.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...client },
    });
  },

  async saveBusinessYear(client, businessYear) {
    return await $fetch(`${this.apiURL}/save-business-year`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...businessYear, client_id: client.id },
    });
  },

  async deleteBusinessYear(businessYear) {
    return await $fetch(`${this.apiURL}/delete-business-year`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { business_year_id: businessYear.id },
    });
  },

  async saveTSEClient(client, tseClient) {
    return await $fetch(`${this.apiURL}/save-tse-client`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...tseClient, client_id: client.id },
    });
  },

  async deleteTSEClient(tseClient) {
    return await $fetch(`${this.apiURL}/delete-tse-client`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { tse_client_id: tseClient.id },
    });
  },

  async deleteClient(client) {
    return await $fetch(`${this.apiURL}`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: client.id },
    });
  },

  async uploadImage(client, image, target) {
    const formData = new FormData();
    formData.append("id", client.id);
    formData.append("image", image);
    formData.append("target", target);
    return await $fetch(`${this.apiURL}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },

  async removeImage(client, target) {
    return await $fetch(`${this.apiURL}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id: client.id, target },
    });
  },

  async saveSort(sort) {
    return await $fetch(`${this.apiURL}/save-sort`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { type: "client", sort },
    });
  },
});
